<template>
  <Layout class="error404">
    <div class="content">
      <div
        ref="lottie"
        class="lottie"
      />
      <div class="tips">
        <h1>404, Page Not Found</h1>
        <p>该页面不存在, 或您无权访问</p>
        <el-button @click="$router.replace('/home/dashboard')">
          返回首页
        </el-button>
      </div>
    </div>
  </Layout>
</template>

<script>
import lottie from 'lottie-web'
export default {
  name: 'Error404',
  mounted () {
    lottie.loadAnimation({
      container: this.$refs.lottie, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/animation/24454-404.json' // the path to the animation json
    })
  }
}
</script>

<style lang="less">
  .error404{
    .layout-content{
      display: flex;
      height: 100%;
      box-sizing: border-box;
      align-items: center;
      .content{
        display: flex;
        align-items: center;
        padding-left: 100px;
        .lottie{
          width: 400px;
          padding-right: 50px;
        }
        .tips{
          padding-bottom: 50px;
        }
      }
    }
  }
</style>
